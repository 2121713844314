import React from 'react';
import { useForm } from 'react-hook-form';
import { FiSearch } from 'react-icons/fi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Button, Col, Nav, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './headerSearch.scss'

const HeaderSearch = () => {
    /**-React Router-**/
    const [searchParams] = useSearchParams()

    /**-Hook Form-**/
    const { register, handleSubmit } = useForm()
    const navigate = useNavigate()

    /**-Variables-**/
    const query = searchParams.get("q")
    
    /**-Event Handlers-**/
    const handleSearchSubmit = async(data) => {
        if(data){
            navigate(`/search/all?q=${data.search}`)
        }
    }

    return (
        <>
   
            <InputGroup className="mx-auto w-50">
                <Form onSubmit={handleSubmit(handleSearchSubmit)} className="d-flex form-inputs w-100">
                    <Form.Control
                        defaultValue={query}
                        {...register('search', { required: true })} 
                        aria-label="Text input with dropdown button"
                        placeholder='Search...'
                        className='rounded-0 rounded-start border-0'
                    />
                    <InputGroup.Text className='searchInput rounded-0 rounded-end border-white bg-white bg-opacity-0'>
                        <Button type="submit" className='bg-white bg-opacity-100 p-0 border-white'>
                            <FiSearch size={22} className=" text-black " />
                        </Button>
                    </InputGroup.Text>

                </Form>

            </InputGroup>
            <Row>
            <Col className='m-0 p-0 d-flex justify-content-center'>
            
                <Nav className='text-white' variant="light">
                        <Link className='p-0' style={{ textDecoration: 'none', color:'white'}} to={'/profiles'}>Profiles</Link>
                </Nav>
                <Nav className='ms-3' variant="light">
                        <Link className='p-0' style={{ textDecoration: 'none', color:'white'}} to={'/projects'}>Projects</Link>
                </Nav>
                <Nav className='ms-3' variant="light">
                        <Link className='p-0' style={{ textDecoration: 'none', color:'white'}} to={'/jobs'}>Jobs</Link>
                </Nav>
                
          
            </Col>
            </Row>


            
        
        </>
    );
};

export default HeaderSearch;