import { apiSlice } from "../api/apiSlice";

export const profileSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfileInfo: builder.query({
      query: (userId) => {
        // Only construct the URL if userId is available
        if (userId) {
          return `/get-profile-info/${userId}`;
        }
        // Return an empty string or a default path if userId is not available
        return "";
      },
      providesTags: ["getProfileInfo"],
    }),
    getPublicProfileInfo: builder.query({
      query: (userId) => `/get-public-profile-info/${userId}`,
      providesTags: ["getPublicProfileInfo"],
    }),
    updateProfileInfo: builder.mutation({
      query: ({ userId, data }) => ({
        url: `/update-profile-info/${userId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProfileInfo", "loggedInUserInfo"],
    }),
    uploadProfileImages: builder.mutation({
      query: ({ userId, data }) => ({
        url: `/upload-profile-images/${userId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProfileInfo"],
    }),
    uploadProfileMedia: builder.mutation({
      query: ({ userId, data }) => ({
        url: `/upload-profile-media/${userId}`,
        method: "POST",
        body: data,
        slate_link: Boolean,
      }),
      invalidatesTags: ["getProfileMedia"],
    }),
    updateProfileMedia: builder.mutation({
      query: ({ userId, id, data }) => ({
        url: `/update-profile-media/${userId}/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        "getProfileMedia",
        "getProfileInfo",
        "loggedInUserInfo",
      ],
    }),
    getProfileMedia: builder.query({
      query: (userId) => `/get-profile-medias/${userId}`,
      method: "GET",
      providesTags: ["getProfileMedia"],
    }),
    getPublicProfileMedia: builder.query({
      query: (userId) => `/get-public-profile-medias/${userId}`,
      method: "GET",
      providesTags: ["getPublicProfileMedia"],
    }),

    deleteProfileMedia: builder.mutation({
      query: ({ userId, id }) => ({
        url: `/delete-profile-media/${userId}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getProfileMedia"],
    }),
    addEvent: builder.mutation({
      query: (data) => ({
        url: `/add-event`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProfileEvents"],
    }),

    getEvent: builder.query({
      query: (eventId) => `/get-event/${eventId}`,
      method: "GET",
      providesTags: ["getEvent", "getProfileInfo"],
    }),

    deleteEvent: builder.mutation({
      query: ({ id }) => ({
        url: `/delete-event/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getProfileEvents"],
    }),
    addEventImage: builder.mutation({
      query: ({ eventId, data }) => ({
        url: `/upload-event-images/${eventId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProfileEvents", "getProfileInfo"],
    }),

    getProfileFolders: builder.query({
      query: (userId) => `/get-gallery-folders/${userId}`,
      method: "GET",
      providesTags: ["getProfileFolders"],
    }),
    getPublicProfileFolders: builder.query({
      query: (userId) => `/get-public-profile-gallery-folders/${userId}`,
      method: "GET",
      providesTags: ["getPublicProfileFolders"],
    }),
    uploadProfileFolders: builder.mutation({
      query: (data) => ({
        url: `/create-gallery-folder`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProfileFolders"],
    }),

    deleteProfileFolder: builder.mutation({
      query: ({ userId, id }) => ({
        url: `/delete-gallery-folder/${userId}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getProfileFolders"],
    }),
    uploadProfileGallery: builder.mutation({
      query: ({ userId, data }) => ({
        url: `/upload-gallery-images/${userId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProfileGallery"],
    }),
    getProfileGallery: builder.query({
      query: (userId) => `/get-gallery-images/${userId}`,
      method: "GET",
      providesTags: ["getProfileGallery"],
    }),
    updateProfileGallery: builder.mutation({
      query: ({ userId, id, data }) => ({
        url: `/update-gallery-image/${userId}/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProfileGallery", "getProfileFolders"],
    }),
    getPublicProfileGalleryImages: builder.query({
      query: (userId) => `/get-public-profile-gallery-images/${userId}`,
      method: "GET",
      providesTags: ["getPublicProfileGallery"],
    }),
    deleteProfileGallery: builder.mutation({
      query: ({ userId, id }) => ({
        url: `/delete-gallery-image/${userId}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getProfileGallery", "getProfileFolders"],
    }),
    dleteProfileSlate: builder.mutation({
      query: (slateId) => ({
        url: `/delete-profile-slate/${slateId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getProfileInfo"],
    }),
    getProfileAudios: builder.query({
      query: (userId) => `/get-profile-audios/${userId}`,
      providesTags: ["getProfileAudios"],
    }),
    getPublicProfileAudios: builder.query({
      query: (userId) => `/get-public-profile-audios/${userId}`,
      providesTags: ["getPublicProfileAudios"],
    }),
    uploadProfileAudio: builder.mutation({
      query: ({ userId, data }) => ({
        url: `/upload-profile-audio/${userId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProfileAudios"],
    }),
    deleteProfileAudio: builder.mutation({
      query: (id) => ({
        url: `/delete-profile-audio/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getProfileAudios"],
    }),
    getProfileProjects: builder.query({
      query: (userId) => `/get-profile-projects/${userId}`,
      providesTags: ["getProfileProjects"],
    }),
    getPublicProfileProjects: builder.query({
      query: (userId) => `/get-public-profile-projects/${userId}`,
      providesTags: ["getPublicProfileProjects"],
    }),
    deleteProfileProject: builder.mutation({
      query: (id) => ({
        url: `/delete-profile-project/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getProfileProjects"],
    }),
    getProfileEvents: builder.query({
      query: (userId) => `/get-profile-events/${userId}`,
      providesTags: ["getProfileEvents"],
    }),
    addProfileGroups: builder.mutation({
      query: ({ slug, data }) => ({
        url: `https://community-api.cficast.com/groups?userslug=${slug}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["getProfileGroups"],
    }),
    getProfileGroups: builder.query({
      query: (slug) =>
        `https://community-api.cficast.com/groups/user?userslug=${slug}`,
      providesTags: ["getProfileGroups"],
    }),
    getProfileAppliedJobs: builder.query({
      query: () => `/get-profile-applied-jobs`,
      providesTags: ["getProfileAppliedJobs"],
    }),
    getProfileReels: builder.query({
      query: (userId) => `/get-profile-reels/${userId}`,
      providesTags: ["getProfileReels"],
    }),
    getPublicProfileReels: builder.query({
      query: (userId) => `/get-public-profile-reels/${userId}`,
      providesTags: ["getPublicProfileReels"],
    }),
    uploadProfileReel: builder.mutation({
      query: ({ userId, data }) => ({
        url: `/upload-profile-reel/${userId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProfileReels"],
    }),
    updateProfileReel: builder.mutation({
      query: ({ id, data }) => ({
        url: `/update-profile-reel/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["getProfileReels"],
    }),
    delteProfileReel: builder.mutation({
      query: (id) => ({
        url: `/delete-profile-reel/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getProfileReels"],
    }),
    getProfileDocs: builder.query({
      query: (userId) => `/get-profile-docs/${userId}`,
      providesTags: ["getProfileDocs"],
    }),
    getPublicProfileDocs: builder.query({
      query: (userId) => `/get-public-profile-docs/${userId}`,
      providesTags: ["getPublicProfileDocs"],
    }),
    uploadProfileDoc: builder.mutation({
      query: ({ userId, data }) => ({
        url: `/upload-profile-doc/${userId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProfileDocs"],
    }),
    deleteProfileDoc: builder.mutation({
      query: (id) => ({
        url: `/delete-profile-doc/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getProfileDocs"],
    }),
  }),
});

export const {
  useGetProfileInfoQuery,
  useGetPublicProfileInfoQuery,
  useUpdateProfileInfoMutation,
  useUploadProfileImagesMutation,
  useUploadProfileMediaMutation,
  useGetProfileMediaQuery,
  useGetPublicProfileMediaQuery,
  useDeleteProfileMediaMutation,
  useAddEventMutation,
  useGetEventQuery,
  useDeleteEventMutation,
  useAddEventImageMutation,
  useUpdateProfileMediaMutation,
  useDleteProfileSlateMutation,
  useGetProfileAudiosQuery,
  useGetPublicProfileAudiosQuery,
  useUploadProfileAudioMutation,
  useDeleteProfileAudioMutation,
  useGetProfileProjectsQuery,
  useGetPublicProfileProjectsQuery,
  useDeleteProfileProjectMutation,
  useGetProfileEventsQuery,
  useAddProfileGroupsMutation,
  useGetProfileGroupsQuery,
  useGetProfileAppliedJobsQuery,
  useGetProfileReelsQuery,
  useGetPublicProfileReelsQuery,
  useUploadProfileReelMutation,
  useUpdateProfileReelMutation,
  useDelteProfileReelMutation,
  useGetProfileDocsQuery,
  useGetPublicProfileDocsQuery,
  useUploadProfileDocMutation,
  useDeleteProfileDocMutation,
  useGetProfileFoldersQuery,
  useGetPublicProfileFoldersQuery,
  useUploadProfileFoldersMutation,
  useDeleteProfileFolderMutation,
  useGetProfileGalleryQuery,
  useUpdateProfileGalleryMutation,
  useGetPublicProfileGalleryImagesQuery,
  useUploadProfileGalleryMutation,
  useDeleteProfileGalleryMutation,
} = profileSlice;
