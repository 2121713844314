import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select'
import { useGetAllCategoriesQuery, useGetSubcategoriesMutation } from '../../features/category/categorySlice';
import { useSearchParams } from 'react-router-dom';
import AgeFilter from './AgeFilter';
import GenderFilters from '../../layout/searchLayout/peopleFilters/GenderFilters';

const CastCrewFilters = ({ setResetForms }) => {
    /**-React Router-**/
    const [searchParams, setSearchParams] = useSearchParams()
    /**-variables-**/
    const updatedSearchParams = new URLSearchParams(searchParams.toString())

    /**-React Hooks-**/
    //states
    const [castOrCrew, setCastOrCrew] = useState("all")

    /**-RTK-**/
    //queries
    const { data: allCategories, isFetching: allCategoriesFetching } = useGetAllCategoriesQuery()
    //mutations
    const [getSubcategories, { data: subcategories, isLoading: getSubcategoriesLoading }] = useGetSubcategoriesMutation()

    /**-Event Handlers-**/
    const handleDeparmentChange = async (department) => {
        await getSubcategories(department?._id)
    }

    const handlePositionChange = async (position) => {
        updatedSearchParams.set('ps', position.label)
        setSearchParams(updatedSearchParams);
    }

    return (
        <div>
            <div className='d-flex gap-3 px-1 py-2 mb-3 border-bottom'>
                <Form.Check
                    onChange={() => {
                        setCastOrCrew("all")
                        setSearchParams({ type: "" })
                        setResetForms(prev => !prev)
                    }}
                    type="radio"
                    name="option"
                    id="all_1"
                    value="all"
                    label="All"
                    defaultChecked
                />
                <Form.Check
                    onChange={() => {
                        setSearchParams({ type: "cast" })
                        setCastOrCrew("cast")
                        setResetForms(prev => !prev)
                    }}
                    type="radio"
                    name="option"
                    id="cast1"
                    value="cast"
                    label="Cast"
                />
                <Form.Check
                    onChange={() => {
                        setSearchParams({ type: "crew" })
                        setCastOrCrew("crew")
                        setResetForms(prev => !prev)
                    }}
                    type="radio"
                    name="option"
                    id="crew1"
                    value="crew"
                    label="Crew"
                />
            </div>
            {
                castOrCrew === 'cast' &&
                <div className="d-grid">
                    <GenderFilters />
                    <AgeFilter />
                </div>
            }
            {
                castOrCrew === 'crew' &&
                <div
                    className='ps-1 pt-2 pb-3 d-grid gap-1'
                >
                    <div className="row g-2 pt-1">
                        <div className="col-12">
                            <label className='small'>Department</label>
                            <Select
                                options={allCategories?.data}
                                isLoading={allCategoriesFetching}
                                onChange={handleDeparmentChange}
                                className='text-capitalize'
                            />
                        </div>
                        <div className="col-12">
                            <label className='small'>Position</label>
                            <Select
                                options={subcategories?.data}
                                isDisabled={subcategories?.data?.length ? false : true}
                                isLoading={getSubcategoriesLoading}
                                onChange={handlePositionChange}
                                className='text-capitalize'
                            />
                        </div>
                    </div>
                </div>
            }
            {
                castOrCrew === "all" &&
                <p className='text-center text-secondary'>Viewing All</p>
            }
        </div>
    );
};

export default CastCrewFilters