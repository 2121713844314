import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import ErrorText from '../../components/errorText/ErrorText';
import SubmitButton from '../../components/submitButton/SubmitButton';

const DateFilter = ({ resetForm }) => {
    /**-React Router-**/
    const [searchParams, setSearchParams] = useSearchParams()
    /**-variables-**/
    const updatedSearchParams = new URLSearchParams(searchParams.toString())

    /**-Hook Form-**/
    const { register, handleSubmit, reset, formState: { isDirty, errors } } = useForm()

    /**-useEffects-**/
    useEffect(() => {
        reset()
    }, [resetForm, reset])

    /**-Event Handlers-**/
    const handleDateSubmit = (data) => {
        let date = new Date(data.date).toISOString()
        updatedSearchParams.set("date", date)
        setSearchParams(updatedSearchParams)
    }

    return (
        <form
            onSubmit={handleSubmit(handleDateSubmit)}
        >
            {/* <label className='fw-semibold text-secondary mb-1'>Date</label> */}
            <div className="d-grid gap-2">
                <input
                    type="date"
                    className='form-control'
                    {...register("date", { required: "date" })}
                />
                {errors?.date && <ErrorText text={errors?.date?.message} />}
                <div className="col-12 d-flex justify-content-end">
                    <SubmitButton text="Apply" disable={!isDirty} />
                </div>
            </div>
        </form>
    );
};

export default DateFilter;