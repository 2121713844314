import React from 'react'
import { Col } from 'react-bootstrap';
import { GiAutoRepair } from "react-icons/gi";


const Downtime = () => {
  return (
      <div className='d-flex flex-column gap-12 mb-11'>
          <div>
              <header className='position-relative'>
                  <div className='min-vh-65 d-flex justify-content-center align-items-center'>
                      <div>
                          <div className='row align-items-start text-center py-3 mt-5 py-md-0'>

                              {/* <h1 style={{ fontFamily: "open-sans, sans-serif", letterSpacing: "8px", fontWeight: 100 }} className='fs-9 col-12 fs-1 text-blue'>Christian Film Industry</h1> */}
                              <Col className='d-flex justify-content-center' >
                            
                                  <div>
                              <h1 style={{ fontFamily: "open-sans, sans-serif", letterSpacing: "30px" }} className='col-12 fs-13 fw-bold  text-secondary'>CFI</h1>
                                  </div>
                                  <div>
                                      <GiAutoRepair size={70} className='mt-2 text-secondary' />
                                  </div>
                              </Col>
                              <h4 style={{ letterSpacing: "4px" }} className='col-12 col-md-8 mb-5 offset-md-2 text-secondary '>We'll be back. </h4>
                              <p className='fs-0 text-secondary'>We are busy updating CFI server for you and we will be back soon.</p>
                          </div>
                      </div>
                  </div>
              </header>          
          </div>


      </div>
  )
}

export default Downtime