import React from 'react';
// import { useId } from 'react';
import { Container, Row, Col, Nav, Tab, Stack, Dropdown} from 'react-bootstrap';
import './topics.scss';
import { BsSearch, BsPersonCircle, BsFillMicFill, BsFillSendFill, BsGearFill, BsCameraVideo } from 'react-icons/bs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

function Topics2() {
    // const content = [
    //     {
    //         id: useId
    //     }
    // ]
    return (
        <>
            <Container fluid className=" ">
                {/* Header */}
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row className="bg-white  ">
                        <Col md={3} className="bg-dark-blue bg-gradient text-white">
                            <div className='p-3 text-uppercase'>
                                <h2>
                                    Topics
                                </h2>
                            </div>
                        </Col>
                        <Col md={6} className="chatHeader bg-gradient text-white">


                            <Tab.Content className='p-3' >

                                <Tab.Pane eventKey='1'>
                                    <h2>
                                        Room 1
                                    </h2>
                                </Tab.Pane>
                                <Tab.Pane eventKey='2'>
                                    <h2>
                                        Birthday
                                    </h2>
                                </Tab.Pane>
                                <Tab.Pane eventKey='3'>
                                    <h2>
                                        chat 3
                                    </h2>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                        <Col className="bg-dark-blue">
                            <Stack direction='horizontal' className='p-3' gap={3}>
                                <span className='ms-auto'>
                                    <button type="button" class="btn btn-light">
                                        <BsCameraVideo />
                                    </button>

                                </span>
                                <span >
                                        <Dropdown>
                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                <BsGearFill />
                                            </Dropdown.Toggle>
                                      
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Room Name</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Make Admin</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Edit Members</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </span>

                            </Stack>
                        </Col>
                    </Row>
                    {/* Body */}
                    <Row fluid className="chat-sideNav bg-secondary vh-75 bg-white">
                        {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first"> */}
                        <Col md={3} className="overflow-auto h-100 p-0 chatSide bg-secondary bg-opacity-10">
                            <Col>
                                <InputGroup className="p-3 bg-dark-blue rounded-0 d-flex">
                                    <span className="rounded-start border-0 bg-white input-group-text" id="addon-wrapping"><BsSearch /></span>
                                    <input type="text" className="rounded-end border-0 form-control" placeholder="Search" aria-label="Search" aria-describedby="addon-wrapping" />
                                </InputGroup>
                            </Col>
                            <Nav variant="tabs" className="flex-column rounded-0">

                                <Nav.Item className=''>
                                    <Nav.Link className='py-4 rounded-0' eventKey='1'>
                                        <Row>
                                            <span className='fs-5'>
                                                Room 1
                                            </span>
                                            <p className='fw-lighter fst-italic text-secondary'>
                                                description
                                            </p>
                                        </Row>
                                    </Nav.Link>
                                    <Nav.Link className='py-4 px-3 rounded-0' eventKey='2'>
                                        <Row>
                                            <span className='fs-5'>
                                                Birthday
                                            </span>
                                            <p className='fw-lighter fst-italic text-secondary'>
                                                description
                                            </p>
                                        </Row>
                                    </Nav.Link>
                                    <Nav.Link className='py-4 px-3 rounded-0' eventKey='3'>
                                        <Row>
                                            <span className='fs-5'>
                                                Test Room friends
                                            </span>
                                            <p className='fw-lighter fst-italic text-secondary'>
                                                description
                                            </p>
                                        </Row>
                                    </Nav.Link>
                                    <Nav.Link className='py-4 px-3 rounded-0' eventKey='3'>
                                        <Row>
                                            <span className='fs-5'>
                                                Test Room friends
                                            </span>
                                            <p className='fw-lighter fst-italic text-secondary'>
                                                description
                                            </p>
                                        </Row>
                                    </Nav.Link>    <Nav.Link className='py-4 px-3 rounded-0' eventKey='3'>
                                        <Row>
                                            <span className='fs-5'>
                                                Test Room friends
                                            </span>
                                            <p className='fw-lighter fst-italic text-secondary'>
                                                description
                                            </p>
                                        </Row>
                                    </Nav.Link>    <Nav.Link className='py-4 px-3 rounded-0' eventKey='3'>
                                        <Row>
                                            <span className='fs-5'>
                                                Test Room friends
                                            </span>
                                            <p className='fw-lighter fst-italic text-secondary'>
                                                description
                                            </p>
                                        </Row>
                                    </Nav.Link>    <Nav.Link className='py-4 px-3 rounded-0' eventKey='3'>
                                        <Row>
                                            <span className='fs-5'>
                                                Test Room friends
                                            </span>
                                            <p className='fw-lighter fst-italic text-secondary'>
                                                description
                                            </p>
                                        </Row>
                                    </Nav.Link>    <Nav.Link className='py-4 px-3 rounded-0' eventKey='3'>
                                        <Row>
                                            <span className='fs-5'>
                                                Test Room friends
                                            </span>
                                            <p className='fw-lighter fst-italic text-secondary'>
                                                description
                                            </p>
                                        </Row>
                                    </Nav.Link>    <Nav.Link className='py-4 px-3 rounded-0' eventKey='3'>
                                        <Row>
                                            <span className='fs-5'>
                                                Test Room friends
                                            </span>
                                            <p className='fw-lighter fst-italic text-secondary'>
                                                description
                                            </p>
                                        </Row>
                                    </Nav.Link>    <Nav.Link className='py-4 px-3 rounded-0' eventKey='3'>
                                        <Row>
                                            <span className='fs-5'>
                                                Test Room friends
                                            </span>
                                            <p className='fw-lighter fst-italic text-secondary'>
                                                description
                                            </p>
                                        </Row>
                                    </Nav.Link>    <Nav.Link className='py-4 px-3 rounded-0' eventKey='3'>
                                        <Row>
                                            <span className='fs-5'>
                                                Test Room friends
                                            </span>
                                            <p className='fw-lighter fst-italic text-secondary'>
                                                description
                                            </p>
                                        </Row>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col md={6} className="overflow-auto h-100 bg-light-sky p-3">
                            <Tab.Content >

                                <Tab.Pane eventKey='1'>
                                    <Stack gap={2}>
                                        {/* right */}
                                        <div className='ms-auto'>
                                            <Stack>
                                                <span className="bg-dark-blue-50 text-white rounded rounded-3 p-3 ">Hello</span>
                                                <span className='px-1 fs-12px ms-auto'>2:30 pm</span>
                                            </Stack>
                                        </div>
                                        {/* right */}
                                        {/* left */}
                                        <div className='me-auto'>
                                            <Stack>
                                                <span className="bg-sky-blue text-white rounded rounded-3 p-3 ">Hey, Hello how are you?</span>
                                                <span className='px-1 fs-12px me-auto'>2:30 pm</span>
                                            </Stack>
                                        </div>
                                        {/* left */}
                                        {/* left */}
                                        <div className='me-auto'>
                                            <Stack>
                                                <span className="bg-sky-blue text-white rounded rounded-3 p-3 ">?</span>
                                                <span className='px-1 fs-12px me-auto'>2:45 pm</span>
                                            </Stack>
                                        </div>
                                        {/* left */}
                                        {/* right */}
                                        <div className='ms-auto'>
                                            <Stack>
                                                <span className="bg-dark-blue-50 text-white rounded rounded-3 p-3 ">I'm good!</span>
                                                <span className='px-1 fs-12px ms-auto'>2:50 pm</span>
                                            </Stack>
                                        </div>
                                        {/* right */}
                                        {/* right */}
                                        <div className='ms-auto'>
                                            <Stack>
                                                <span className="bg-dark-blue-50 text-white rounded rounded-3 p-3 ">How about you?</span>
                                                <span className='px-1 fs-12px ms-auto'>2:53 pm</span>
                                            </Stack>
                                        </div>
                                        {/* right */}
                                    </Stack>
                                </Tab.Pane>
                                <Tab.Pane eventKey='2'>
                                    <Stack gap={2}>
                                        {/* right */}
                                        <div className='ms-auto'>
                                            <Stack>
                                                <span className="bg-dark-blue-50 text-white rounded rounded-3 p-3 ">Happy Birthday John🎂</span>
                                                <span className='px-1 fs-12px ms-auto'>2:30 pm</span>
                                            </Stack>
                                        </div>
                                        {/* right */}
                                        {/* left */}
                                        <div className='me-auto'>
                                            <Stack>
                                                <span className="bg-sky-blue text-white rounded rounded-3 p-3 ">Thank you!! 😇</span>
                                                <span className='px-1 fs-12px me-auto'>2:30 pm</span>
                                            </Stack>
                                        </div>
                                        {/* left */}
                                        {/* left */}
                                        <div className='me-auto'>
                                            <Stack>
                                                <span className="bg-sky-blue text-white rounded rounded-3 p-3 ">Let's gather in the evening</span>
                                                <span className='px-1 fs-12px me-auto'>2:45 pm</span>
                                            </Stack>
                                        </div>
                                        {/* left */}
                                        {/* right */}
                                        <div className='ms-auto'>
                                            <Stack>
                                                <span className="bg-dark-blue-50 text-white rounded rounded-3 p-3 ">sure!</span>
                                                <span className='px-1 fs-12px ms-auto'>2:50 pm</span>
                                            </Stack>
                                        </div>
                                        {/* right */}
                                        {/* right */}
                                        <div className='ms-auto'>
                                            <Stack>
                                                <span className='px-1 fs-12px me-auto'>you</span>
                                                <span className="bg-dark-blue-50 text-white rounded rounded-3 p-3 ">I will notify rest of the people</span>
                                                <span className='px-1 fs-12px ms-auto'>2:53 pm</span>
                                            </Stack>
                                        </div>
                                        {/* right */}
                                        {/* left */}
                                        <div className='me-auto'>
                                            <Stack>
                                                <span className="bg-sky-blue text-white rounded rounded-3 p-3 ">Thanks!!</span>
                                                <span className='px-1 fs-12px me-auto'>2:45 pm</span>
                                            </Stack>
                                        </div>
                                        {/* left */}
                                        <div className='me-auto'>
                                            <Stack>
                                                <span className="bg-sky-blue text-white rounded rounded-3 p-3 ">Thanks!!</span>
                                                <span className='px-1 fs-12px me-auto'>2:45 pm</span>
                                            </Stack>
                                        </div>
                                        <div className='me-auto'>
                                            <Stack>
                                                <span className="bg-sky-blue text-white rounded rounded-3 p-3 ">Thanks!!</span>
                                                <span className='px-1 fs-12px me-auto'>2:45 pm</span>
                                            </Stack>
                                        </div>
                                        <div className='me-auto'>
                                            <Stack>
                                                <span className="bg-sky-blue text-white rounded rounded-3 p-3 ">Thanks!!</span>
                                                <span className='px-1 fs-12px me-auto'>2:45 pm</span>
                                            </Stack>
                                        </div>
                                        <div className='me-auto'>
                                            <Stack>
                                                <span className="bg-sky-blue text-white rounded rounded-3 p-3 ">Thanks!!</span>
                                                <span className='px-1 fs-12px me-auto'>2:45 pm</span>
                                            </Stack>
                                        </div>
                                    </Stack>
                                </Tab.Pane>
                                <Tab.Pane eventKey='3'>
                                    <Stack gap={2}>
                                        {/* left */}
                                        <div className='me-auto'>
                                            <Stack>
                                                <span className="bg-sky-blue text-white rounded rounded-3 p-3 ">Hey all!! Wassup</span>
                                                <span className='px-1 fs-12px me-auto'>2:30 pm</span>
                                            </Stack>
                                        </div>
                                        {/* left */}
                                        {/* right */}
                                        <div className='ms-auto'>
                                            <Stack>
                                                <span className="bg-dark-blue-50 text-white rounded rounded-3 p-3 ">Hello!!</span>
                                                <span className='px-1 fs-12px ms-auto'>2:30 pm</span>
                                            </Stack>
                                        </div>
                                        {/* right */}
                                        {/* left */}
                                        <div className='me-auto'>
                                            <Stack>
                                                <span className="bg-sky-blue text-white rounded rounded-3 p-3 ">Hi popzz!! it's been a while</span>
                                                <span className='px-1 fs-12px me-auto'>2:45 pm</span>
                                            </Stack>
                                        </div>
                                        {/* left */}
                                        {/* left */}
                                        <div className='me-auto'>
                                            <Stack>
                                                <span className="bg-sky-blue text-white rounded rounded-3 p-3 ">How about a video call?</span>
                                                <span className='px-1 fs-12px me-auto'>2:45 pm</span>
                                            </Stack>
                                        </div>
                                        {/* left */}
                                        {/* right */}
                                        <div className='ms-auto'>
                                            <Stack>
                                                <span className="bg-dark-blue-50 text-white rounded rounded-3 p-3 ">sure! Give me few minutes</span>
                                                <span className='px-1 fs-12px ms-auto'>2:50 pm</span>
                                            </Stack>
                                        </div>
                                        {/* right */}
                                        {/* right */}
                                        <div className='ms-auto'>
                                            <Stack>
                                                <span className='px-1 fs-12px me-auto'>you</span>
                                                <span className="bg-dark-blue-50 text-white rounded rounded-3 p-3 ">😃</span>
                                                <span className='px-1 fs-12px ms-auto'>2:53 pm</span>
                                            </Stack>
                                        </div>
                                        {/* right */}
                                        {/* left */}
                                        <div className='me-auto'>
                                            <Stack>
                                                <span className="bg-sky-blue text-white rounded rounded-3 p-3 ">alrighty!!</span>
                                                <span className='px-1 fs-12px me-auto'>2:45 pm</span>
                                            </Stack>
                                        </div>
                                        {/* left */}
                                    </Stack>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                        <Col fluid className=" bg-secondary bg-opacity-10 p-0">
                            <div className='bg-sky-blue p-2 m-0'>
                                <span className='text-white'>
                                    Online Now
                                </span>
                            </div>
                            <Tab.Content >
                                <Tab.Pane eventKey='1'>
                                    <Stack className='p-2' gap={3}>
                                        <span className='px-3'>John</span>
                                        <span className='px-3'>Mark</span>
                                        <span className='px-3'>Luis</span>
                                        <span className='px-3'>Chris</span>
                                        <span className='px-3'>Anonymous</span>
                                        <span className='px-3'>NoId</span>
                                    </Stack>
                                </Tab.Pane>
                                <Tab.Pane eventKey='2'>
                                    <Stack className='p-2' gap={3}>
                                        <span className='px-3'>John</span>
                                        <span className='px-3'>Mark</span>
                                        <span className='px-3'>Luis</span>

                                    </Stack>
                                </Tab.Pane>
                                <Tab.Pane eventKey={'3'}>
                                    <Stack className='p-2' gap={3}>
                                        <span className='px-3'>John</span>
                                        <span className='px-3'>Mark</span>

                                    </Stack>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                        {/* </Tab.Container> */}
                    </Row>


                    {/* Footer */}
                    <Row className="">
                        <Col md={3} className="bg-secondary  d-flex">
                            <div className='p-3 text-white'>
                                <h2>
                                    <BsPersonCircle classNameName='mt-2 text-secondary' size={40} />
                                </h2>
                            </div>
                            <div className='py-4'>
                                <span className='text-white fs-5'>
                                    Available
                                </span>
                            </div>
                        </Col>
                        <Col md={6} className="p-0 bg-light-sky">
                            <InputGroup className="mb-0 rounded-0 p-1">
                                <Form.Control
                                    className='rounded-0 p-3'
                                    placeholder="Type something..."
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                />
                                <Button className='bg-secondary text-white rounded-0' variant="outline-secondary" id="button-addon2">
                                    <BsFillMicFill />
                                </Button>
                                <Button className='bg-secondary text-white rounded-0' variant="outline-white" id="button-addon2">
                                    <BsFillSendFill />
                                </Button>
                            </InputGroup>                  </Col>
                        <Col className="bg-secondary">
                            <Form>

                                {/* <div key={`default-checkbox`} className=" text-white m-3">
                                    <Form.Check // prettier-ignore
                                        type='checkbox'
                                        id={`default-checkbox`}
                                        label={` Guest Access`}
                                    />

                                </div> */}

                            </Form>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </>
    )
}

export default Topics2
