import React, { useEffect, useState } from 'react';
import './TestAuth.css'
import ErrorText from '../../components/errorText/ErrorText';
import logo from '../../assets/images/logo.png'
import { Outlet } from 'react-router-dom';

const TestAuth = () => {
    /**-React Hooks-**/
    const [hasAccess, setHasAccess] = useState(!!localStorage.getItem("cfi_testing"));
    const [password, setPassword] = useState(null);
    const [invalidPass, setInvalidPass] = useState(false);

    /**-Event Handlers-**/
    const handleChange = (e) => {
        setInvalidPass(false)
        setPassword(e.target.value)
    }
    const handleTestingLoginSubmit = (e) => {
        e.preventDefault()
        if (password && (password === process.env.REACT_APP_TESTING_PASS)) {
            localStorage.setItem('cfi_testing', password)
            window.location.reload()
        } else{
            setInvalidPass(true)
        }
    };

    if (hasAccess) return <Outlet/>;

    return (
        <div className='bg-dark-blue beta-test'>
            <div className='d-grid gap-4 bg-white bg-opacity-10 rounded-5'>
                <div>
                    <img src={logo} width={'80%'} alt='logo'/>
                    {/* <h1 className='display-1 text-light'>CFI Cast</h1> */}
                    <h3 className='text-secondary'>Coming Soon</h3>
                </div>
                <form onSubmit={handleTestingLoginSubmit} className='d-grid gap-2'>
                    <label>Beta testing password:</label>
                    <div className='px-5'>
                    <input onChange={handleChange} type="password" className='form-control' required />
                    </div>
                    {invalidPass && <ErrorText text="Invalid password" text2='' />}
                    <div className='py-4'>
                        <button type='submit' className='btn btn-primary'>Login</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default TestAuth;