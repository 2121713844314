import React, { useEffect, useState } from 'react';
import { useSearchJobsMutation } from '../../features/job/jobSlice';
import { useParams, useSearchParams } from 'react-router-dom';
import JobCard from './JobCard';
import Sidebar from './Sidebar';
import DotLoader from '../../components/dotLoader/DotLoader';
import { TbFilterSearch } from 'react-icons/tb';
import { LiaFilterSolid } from 'react-icons/lia';
import { Col } from 'react-bootstrap';

const Jobs = () => {
    /**-React Router-**/
    const { pathname } = useParams()
    const [searchParams] = useSearchParams()

    /**-variables-**/
    const type = searchParams.get('type')
    const gender = searchParams.get('g')
    const age = searchParams.get('age')
    const lc = searchParams.get('lc')
    const date = searchParams.get('date')
    const pt = searchParams.get('pt')
    const ps = searchParams.get('ps')

    /**-RTK-**/
    //mutations
    const [searchJobs, { data: jobs, isLoading: searchJobsLoading }] = useSearchJobsMutation()

    /**-useEffects-**/
    useEffect(() => {
        if (!searchJobsLoading) {
            searchJobs({ type: type || "all", gender, age, lc, date, pt, ps })
                .then(result => null)
                .catch(err => console.log(err))
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, searchParams, searchJobs])

    //states
    const [filter, setFilter] = useState(true)
    const [filterScreen, setFilterScreen] = useState(true)

    return (
        <div className='bg-light'>
            <div className='container py-3 py-md-4 py-lg-5'>
                <Col xl={3} sm={6} md={4} className='position-relative'>
                    <button onClick={() => { setFilter(!filter); setFilterScreen(!filterScreen) }} className='btn btn-secondary m-3'>
                        {filterScreen ? <TbFilterSearch size={24} /> : <LiaFilterSolid size={24} />}
                    </button>

                </Col>
                <div className='row mx-0 min-vh-100 position-relative my-3'>
                    <div className={`${filter ? 'd-none' : 'd-block'} position-relative col-12 col-lg-3`}>
                        <div className='bg-white border-end d-flex flex-column rounded-3 position-sticky top-0 vh-100'>
                            <div className='border-bottom'>
                                <p className='fs-4 fw-bold m-0 p-3'>Jobs</p>
                            </div>
                            <div className="dahs_navbar h-100 overflow-auto p-2">
                                <Sidebar />
                            </div>
                        </div>
                    </div>
                    <Col xl={filterScreen ? 12 : 9} sm={filterScreen ? 12 : 6} md={filterScreen ? 12 : 8}>
                        <div className="row g-3">
                            {
                                jobs?.data?.map(job => (
                                    <div key={job._id} className="col-12 col-lg-4">
                                        <JobCard
                                            cardData={job}
                                        />
                                    </div>
                                ))
                            }
                            {
                                !jobs?.data?.length &&
                                <div className="vh-100 d-grid align-items-center bg-white rounded shadow-sm">
                                    {
                                        searchJobsLoading
                                            ?
                                            <DotLoader />
                                            :
                                            <p className='fs-4 fw-bold text-center text-secondary'>No jobs found.</p>
                                    }
                                </div>
                            }
                        </div>
                    </Col>
                </div>
            </div>
        </div>
    );
};

export default Jobs;