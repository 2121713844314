import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useApplyToProjectOpeningMutation } from "../../features/project/projectSlice";
import { errorToastUtil, successToastUtil } from "../../utils/sweetAlertUtils";
import useSweetAlert from "../../hooks/useSweetAlert";
import SubmitButton from "../../components/submitButton/SubmitButton";
import { useGetLoggedInUserInfoQuery } from "../../features/auth/authSlice";

const JobCard = ({ cardData, canApply = true }) => {
  let project = cardData?.project?.[0];

  /**-React Hooks-**/
  const [applied, setApplied] = useState(false);

  /**-Custom Hooks-**/
  const showAlert = useSweetAlert();

  /**-RTK-**/
  //queries
  const { data: loggedInUserInfo } = useGetLoggedInUserInfoQuery();
  //mutation
  const [applyToProjectOpening, { isLoading: applyToProjectOpeningLoading }] =
    useApplyToProjectOpeningMutation();

  /**-Event Handlers-**/
  const handleAppplyToOpeningClick = async () => {
    try {
      await applyToProjectOpening(cardData._id).unwrap();
      showAlert.fire({
        ...successToastUtil,
        title: "Application Submitted Successfully",
      });
    } catch (error) {
      showAlert.fire(errorToastUtil);
    }
  };

  return (
    <div className="bg-white p-2 rounded shadow-md">
      <Link className="nav-link" to={`/project/${project?._id}`}>
        <img
          src={project?.thumbnail}
          alt={project?.name}
          className="object-fit-cover img-fluid"
        />
      </Link>
      <div className="px-1 pt-1">
        <div className="d-flex gap-2">
          <div className="flex-grow-1">
            <Link
              to={`/project/${project?._id}`}
              className="nav-link d-inline-block fw-bold flex-grow-1 mt-1 fs-5"
            >
              {project?.name}
            </Link>
          </div>
          {canApply && (
            <div>
              <SubmitButton
                text={
                  cardData?.applicants?.includes(
                    loggedInUserInfo?.data?.user_id
                  ) || applied
                    ? "Applied"
                    : "Apply"
                }
                className="btn btn-primary"
                onClick={() => {
                  handleAppplyToOpeningClick();
                  setApplied(true);
                }}
                isLoading={applyToProjectOpeningLoading}
                disable={
                  applied ||
                  cardData?.applicants?.includes(
                    loggedInUserInfo?.data?.user_id
                  )
                    ? true
                    : false
                }
                disableTitle="Already applied to this opening."
              />
            </div>
          )}
        </div>
        <div className="py-2">
          <p className="m-0 text-capitalize">Type: {cardData?.type}</p>
          {cardData?.type === "crew" ? (
            <p className="m-0 text-capitalize">
              Position: {cardData?.position}
            </p>
          ) : (
            <p className="m-0 text-capitalize">Role: {cardData?.role}</p>
          )}
          {cardData?.deadline && (
            <p className="m-0 text-capitalize">
              Deadline:{" "}
              {new Intl.DateTimeFormat("en-US", { dateStyle: "full" }).format(
                new Date(cardData?.deadline)
              )}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobCard;
