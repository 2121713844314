import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./assets/css/global.scss";
import "./assets/css/custom.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-step-progress-bar/styles.css";
import store from "./store/store";
import { setupListeners } from "@reduxjs/toolkit/query";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import kc from "./utils/keycloak";

/** Providers */
import { Provider } from "react-redux";
import {
  ReactKeycloakProvider as KeycloakProvider,
  useKeycloak,
} from "@react-keycloak/web";
import {
  LivepeerConfig,
  createReactClient,
  studioProvider,
} from "@livepeer/react";
import { useGetLoggedInUserInfoQuery } from "./features/auth/authSlice";

const livepeerClient = createReactClient({
  provider: studioProvider({
    apiKey: process.env.REACT_APP_LIVEPEER,
  }),
});

const livepeerTheme = {
  colors: {
    accent: "rgb(0, 145, 255)",
    containerBorderColor: "rgba(0, 145, 255, 0.9)",
  },
  fonts: {
    display: "Inter",
  },
};

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

/** Keycloak configuration and helpers */
let kcInitOptions = {
  onLoad: "check-sso",
  silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
};
const CleanUrlComponent = () => {
  const { keycloak } = useKeycloak();

  const { refetch: refetchUserInfo } = useGetLoggedInUserInfoQuery();

  keycloak.onTokenExpired = function () {
    keycloak.updateToken(5)
      .then(function (refreshed) {
        /** Token is then refreshed and set in the localStorage */
        if (refreshed) localStorage.setItem("token", keycloak.token);
      }).catch(function () {
        /** If an error occurs or session is expired, logout the user */
        localStorage.removeItem("token");
        keycloak.logout();
      }); 
    };

  useEffect(() => {
    if (keycloak && keycloak.authenticated) {
      /** Fetch user info right after authentication */
      refetchUserInfo();
      const currentUrl = window.location.href;
      /** Method for cleaning the url seamlessly */
      const cleanUrl = currentUrl.split("?")[0];
      window.history.replaceState({}, document.title, cleanUrl);
      /** Sets the token on localStorage*/
      const { token } = keycloak;
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  }, [keycloak, keycloak.authenticated, window.location.pathname, refetchUserInfo]);

  return null;
};

setupListeners(store.dispatch);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <KeycloakProvider
    authClient={kc}
    initOptions={kcInitOptions}
    onEvent={(event, error) => {
      if (event === "onAuthSuccess") {
        const token = kc.token;
        localStorage.setItem("token", token);
      }
    }}
  >
    <Provider store={store}>
      <LivepeerConfig client={livepeerClient} theme={livepeerTheme}>
        <App />
        <CleanUrlComponent />
      </LivepeerConfig>
    </Provider>
  </KeycloakProvider>
);

reportWebVitals();
