import React from 'react';
import { useSearchParams } from 'react-router-dom';

const GenderFilters = () => {
    /**-React Router-**/
    const [searchParams, setSearchParams] = useSearchParams()
    /**-variables-**/
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    /**-Event Handlers-**/
    const handleGenderChange = async (e) => {
        if (e.target.value) {
            if (e.target.value === 'both') {
                updatedSearchParams.set('g', '')
                setSearchParams(updatedSearchParams);
            } else {
                updatedSearchParams.set('g', e.target.value)
                setSearchParams(updatedSearchParams);
            }
        }
    }

    return (
        // <form ref={genderFormRef} onSubmit={(e) => e.preventDefault()} className='ps-1 pt-2 pb-3 d-grid gap-1'>
        <form onSubmit={(e) => e.preventDefault()} className='ps-1 pt-2 pb-3 d-grid gap-1'>
            <p className='text-secondary fw-semibold m-0'>Gender:</p>
            <div className='d-flex gap-1 align-items-center'>
                <input onChange={handleGenderChange} type="radio" name="gender" value="male" id="gender_male" className='cursor-pointer' />
                <label htmlFor='gender_male' className='cursor-pointer small text-secondary' >Male</label>
            </div>
            <div className='d-flex gap-1 align-items-center'>
                <input onChange={handleGenderChange} type="radio" name="gender" value="female" id="gender_female" className='cursor-pointer' />
                <label htmlFor='gender_female' className='cursor-pointer small text-secondary' >Female</label>
            </div>
            <div className='d-flex gap-1 align-items-center'>
                <input onChange={handleGenderChange} type="radio" name="gender" value="both" id="gender_both" className='cursor-pointer' />
                <label htmlFor='gender_both' className='cursor-pointer small text-secondary' >Both</label>
            </div>
        </form>
    );
};

export default GenderFilters;