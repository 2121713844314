import { useState, useEffect } from "react";
import { usePostLoginMutation, usePostSignUpMutation } from "../../features/Xchat/api";
import { BsFillChatFill } from 'react-icons/bs'

const Login = ({ setUser, setSecret }) => {
  const [isRegister, setIsRegister] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [triggerLogin, resultLogin] = usePostLoginMutation();
  const [triggerSignUp] = usePostSignUpMutation();

  const handleLogin = () => {
    triggerLogin({ username, password });
  };

  const handleRegister = () => {
    triggerSignUp({ username, password });
  };

  useEffect(() => {
    if (resultLogin.data?.response) {
      setUser(username);
      setSecret(password);
    }
  }, [resultLogin.data]); // eslint-disable-line

  return (
    <div className="login-page p-5">
      <div className="login-container rounded-3 p-5 bg-white bg-opacity-10 text-center">
        <h1 className="fw-lighter">TOPICS CHAT... <BsFillChatFill size={30} className="mb-2" /></h1>
        {!isRegister ? <h4 className="fw-lighter">Login</h4> : <h4 className="fw-lighter">Register</h4>}
        <div>
          <input
            className="login-input"
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            className="login-input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="d-flex mt-3 justify-content-between">
          <p
            className="register-change"
            onClick={() => setIsRegister(!isRegister)}
          >
            {isRegister ? "Already a user?" : "Are you a new user?"}
          </p>
          <div className="login-actions">
            {isRegister ? (
              <button className="btn btn-secondary" type="button" onClick={handleRegister}>
                Register
              </button>
            ) : (
              <button className="btn btn-secondary" type="submit" onClick={handleLogin}>
                Login
              </button>
            )}
          </div>
        </div>

      </div>
    </div>
  );
};

export default Login;
