import React from 'react';

const SubmitButton = ({ text = "Add", isLoading = false, disable = false, disableTitle = 'No changes to save.', className = "", ...rest }) => {
    return (
        isLoading
            ?
            <div className={`${className ? className : "btn btn-primary px-4"} opacity-50`}>
                <div className="spinner-border ms-auto spinner-border-sm" role="status" aria-hidden="true"></div>
            </div>
            :
            disable
                ?
                <div title={disableTitle} className={`${className ? className : "btn btn-primary px-4"} opacity-50`}>
                    {text}
                </div>
                :
                <button type='submit' className={`${className ? className : "btn btn-primary px-4"}`} {...rest}>{text}</button>
    );
};

export default SubmitButton;