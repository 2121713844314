import { apiSlice } from "../api/apiSlice";
import { buildParam } from "../utils";

export const projectSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //project creation
    getAllProjectTypes: builder.query({
      query: () => `/get-all-project-types`,
      providesTags: ["getProjectTypes"],
    }),
    //project filtering
    getAllProjects: builder.mutation({
      query: ({ fromBudget, toBudget, types }) => ({
        url: `/get-all-projects?${buildParam("fb", fromBudget)}${buildParam(
          "tb",
          toBudget
        )}${buildParam("t", types)}`,
        method: "GET",
      }),
      providesTags: ["getAllProjects"],
    }),
    //project page
    getProject: builder.query({
      query: (projectId) => `/get-project/${projectId}`,
      providesTags: ["getProject"],
    }),
    addProject: builder.mutation({
      query: (data) => ({
        url: `/add-project`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProfileProjects"],
    }),
    uploadProjectImages: builder.mutation({
      query: ({ projectId, data }) => ({
        url: `/upload-project-images/${projectId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProject", "getProfileProjects"],
    }),
    updateProjectInfo: builder.mutation({
      query: ({ id, data }) => ({
        url: `/update-project-info/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProject", "getProfileProjects"],
    }),
    updateProjectVisibility: builder.mutation({
      query: ({ id, data }) => ({
        url: `/update-project-visibility/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProject", "getProfileProjects"],
    }),
    getProjectCasts: builder.query({
      query: (projectId) => `/get-project-casts/${projectId}`,
      providesTags: ["getProjectCasts"],
    }),
    addProjectCast: builder.mutation({
      query: ({ projectId, data }) => ({
        url: `/add-project-cast/${projectId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProjectCasts"],
    }),
    getCast: builder.mutation({
      query: (id) => ({
        url: `/get-cast/${id}`,
      }),
      providesTags: ["getCast"],
    }),
    updateProjectCast: builder.mutation({
      query: ({ projectId, id, data }) => ({
        url: `/update-project-cast/${projectId}/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProjectCasts"],
    }),
    deleteProjectCast: builder.mutation({
      query: ({ projectId, id }) => ({
        url: `/delete-project-cast/${projectId}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getProjectCasts"],
    }),
    getProjectCrews: builder.query({
      query: (projectId) => `/get-project-crews/${projectId}`,
      providesTags: ["getProjectCrews"],
    }),
    addProjectCrew: builder.mutation({
      query: ({ projectId, data }) => ({
        url: `/add-project-crew/${projectId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProjectCrews"],
    }),
    updateProjectCrew: builder.mutation({
      query: ({ projectId, id, data }) => ({
        url: `/update-project-crew/${projectId}/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProjectCrews"],
    }),
    deleteProjectCrew: builder.mutation({
      query: ({ projectId, id }) => ({
        url: `/delete-project-crew/${projectId}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getProjectCrews"],
    }),
    getProjectGameDevs: builder.query({
      query: (projectId) => `/get-project-game-devs/${projectId}`,
      providesTags: ["getProjectGameDevs"],
    }),
    addProjectGamedev: builder.mutation({
      query: ({ projectId, data }) => ({
        url: `/add-project-game-dev/${projectId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProjectGameDevs"],
    }),
    updateProjectGameDev: builder.mutation({
      query: ({ projectId, id, data }) => ({
        url: `/update-project-game-dev/${projectId}/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["getProjectGameDevs"],
    }),
    deleteProjectGameDev: builder.mutation({
      query: ({ projectId, id }) => ({
        url: `/delete-project-game-dev/${projectId}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getProjectGameDevs"],
    }),
    getProjectOverview: builder.query({
      query: (projectId) => `/get-project-overview/${projectId}`,
      providesTags: ["getProjectOverview"],
    }),
    addProjectOverview: builder.mutation({
      query: ({ projectId, data }) => ({
        url: `/add-project-overview/${projectId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProjectOverview"],
    }),
    updateProjectOverview: builder.mutation({
      query: ({ projectId, id, data }) => ({
        url: `/update-project-overview/${projectId}/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProjectOverview"],
    }),
    getProjectSubmission: builder.query({
      query: (projectId) => `/get-project-submission/${projectId}`,
      providesTags: ["getProjectSubmission"],
    }),
    addProjectSubmission: builder.mutation({
      query: ({ projectId, data }) => ({
        url: `/add-project-submission/${projectId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProjectSubmission"],
    }),
    updateProjectSubmission: builder.mutation({
      query: ({ projectId, id, data }) => ({
        url: `/update-project-submission/${projectId}/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProjectSubmission"],
    }),
    getProjectVideos: builder.query({
      query: (projectId) => `/get-project-videos/${projectId}`,
      providesTags: ["getProjectVideos"],
    }),
    uploadProjectVideo: builder.mutation({
      query: ({ projectId, data }) => ({
        url: `/upload-project-video/${projectId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProjectVideos"],
    }),
    updateProjectVideo: builder.mutation({
      query: ({ id, data }) => ({
        url: `/update-project-video/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["getProjectVideos"],
    }),
    deleteProjectVideo: builder.mutation({
      query: ({ projectId, id }) => ({
        url: `/delete-project-video/${projectId}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getProjectVideos"],
    }),
    //project review
    getProjectReviews: builder.query({
      query: (projectId) => `/get-project-reviews/${projectId}`,
      providesTags: ["getProjectReviews"],
    }),
    addProjectReview: builder.mutation({
      query: ({ projectId, data }) => ({
        url: `/add-project-review/${projectId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProjectReviews"],
    }),
    updateProjectReview: builder.mutation({
      query: ({ id, data }) => ({
        url: `/update-project-review/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["getProjectReviews"],
    }),
    deleteProjectReview: builder.mutation({
      query: (id) => ({
        url: `/delete-project-review/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getProjectReviews"],
    }),
    //project openings
    getProjectOpenings: builder.query({
      query: (projectId) => `/project/${projectId}/openings`,
      providesTags: ["getProjectOpenings"],
    }),
    addProjectCastOpening: builder.mutation({
      query: ({ projectId, data }) => ({
        url: `/add-project-cast-opening/${projectId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProjectOpenings"],
    }),
    updateProjectCastOpening: builder.mutation({
      query: ({ projectId, id, data }) => ({
        url: `/update-project-cast-opening/${projectId}/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProjectOpenings"],
    }),
    deleteProjectCastOpening: builder.mutation({
      query: ({ projectId, id }) => ({
        url: `/delete-project-cast-opening/${projectId}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getProjectOpenings"],
    }),
    addProjectCrewOpening: builder.mutation({
      query: ({ projectId, data }) => ({
        url: `/project/${projectId}/crew/opening`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: ["getProjectOpenings"],
    }),
    updateProjectCrewOpening: builder.mutation({
      query: ({ projectId, id, data }) => ({
        url: `/project/${projectId}/crew/opening/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["getProjectOpenings"],
    }),
    deleteProjectCrewOpening: builder.mutation({
      query: ({ projectId, id }) => ({
        url: `/project/${projectId}/crew/opening/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getProjectOpenings"],
    }),
    getOpeningApplicants: builder.mutation({
      query: ({ projectId, id, data }) => ({
        url: `/project/${projectId}/opening/${id}/applicants`,
        method: "POST",
        body: data,
      }),
    }),
    applyToProjectOpening: builder.mutation({
      query: (id) => ({
        url: `/project/opening/${id}/apply`,
        method: "POST",
      }),
      invalidatesTags: ["getProjectOpenings"],
    }),
    getProjectOpeningsApplicants: builder.query({
      query: (projectId) => `/project/${projectId}/openings/applicants`,
      providesTags: ["getProjectOpeningsApplicants"],
    }),
    revokeJobApplication: builder.mutation({
      query: (jobId) => ({
        url: `/jobs/revoke/${jobId}`,
        method: "PATCH",
      }),
      invalidatesTags: ["getProjectOpenings"],
    }),
  }),
});

export const {
  useGetAllProjectTypesQuery,
  useGetAllProjectsMutation,
  useGetProjectQuery,
  useAddProjectMutation,
  useUploadProjectImagesMutation,
  useUpdateProjectInfoMutation,
  useUpdateProjectVisibilityMutation,
  useAddProjectCastMutation,
  useGetProjectCastsQuery,
  useGetCastMutation,
  useUpdateProjectCastMutation,
  useDeleteProjectCastMutation,
  useGetProjectCrewsQuery,
  useAddProjectCrewMutation,
  useUpdateProjectCrewMutation,
  useDeleteProjectCrewMutation,
  useGetProjectGameDevsQuery,
  useAddProjectGamedevMutation,
  useUpdateProjectGameDevMutation,
  useDeleteProjectGameDevMutation,
  useGetProjectOverviewQuery,
  useAddProjectOverviewMutation,
  useUpdateProjectOverviewMutation,
  useGetProjectSubmissionQuery,
  useAddProjectSubmissionMutation,
  useUpdateProjectSubmissionMutation,
  useGetProjectVideosQuery,
  useUploadProjectVideoMutation,
  useUpdateProjectVideoMutation,
  useDeleteProjectVideoMutation,
  useGetProjectReviewsQuery,
  useAddProjectReviewMutation,
  useUpdateProjectReviewMutation,
  useDeleteProjectReviewMutation,
  useGetProjectOpeningsQuery,
  useAddProjectCastOpeningMutation,
  useUpdateProjectCastOpeningMutation,
  useDeleteProjectCastOpeningMutation,
  useAddProjectCrewOpeningMutation,
  useUpdateProjectCrewOpeningMutation,
  useDeleteProjectCrewOpeningMutation,
  useGetOpeningApplicantsMutation,
  useApplyToProjectOpeningMutation,
  useGetProjectOpeningsApplicantsQuery,
  useRevokeJobApplicationMutation,
} = projectSlice;
