import React from 'react';
import { Outlet } from 'react-router-dom'
import Footer from '../../components/Footer';
import Header from '../../components/header/Header';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MainLayout = () => {

    return (
        <div className='min-vh-100 d-flex flex-column'>
            <ToastContainer
                className={'pt-5 m-1'}
                position="top-right"
                autoClose={2500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Header />
            <Outlet />
            <div className="position-sticky top-100">
                <Footer />
            </div>
        </div>
    );
};

export default MainLayout;