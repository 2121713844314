import React from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import SubmitButton from '../../components/submitButton/SubmitButton';
import ErrorText from '../../components/errorText/ErrorText';

const AgeFilter = () => {
    /**-React Router-**/
    const [searchParams, setSearchParams] = useSearchParams()
    /**-variables-**/
    const updatedSearchParams = new URLSearchParams(searchParams.toString())

    /**-Hook Form-**/
    const { register, handleSubmit, formState: { isDirty, errors } } = useForm()

    /**-Event Handler-**/
    const handleAgeFormSubmit = (data) => {
        const ageRange = `${data.from_age}-${data.to_age}`
        updatedSearchParams.set("age", ageRange)
        setSearchParams(updatedSearchParams)
    }

    return (
        <div>
            <label className='text-secondary fw-semibold mb-1'>Age Range:</label>
            <form
                onSubmit={handleSubmit(handleAgeFormSubmit)}
                className='d-grid gap-2'
            >
                <div className="d-grid gap-2">
                    <input
                        type="number"
                        className='form-control'
                        placeholder="From"
                        onWheel={(e) => e.target.blur()}
                        {...register('from_age', { required: "from value" })}
                    />
                    {errors?.from_age && <ErrorText text={errors?.from_age?.message} />}
                    <input
                        type="number"
                        className='form-control'
                        placeholder="To"
                        onWheel={(e) => e.target.blur()}
                        {...register('to_age', { required: "to value" })}
                    />
                    {errors?.to_age && <ErrorText text={errors?.to_age?.message} />}
                </div>
                <div className="col-12 d-flex justify-content-end">
                    <SubmitButton text="Apply" disable={!isDirty} />
                </div>
            </form>
        </div>
    );
};

export default AgeFilter;