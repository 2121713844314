import { apiSlice } from "../api/apiSlice";

export const authSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLoggedInUserInfo: builder.query({
      query: () => {
        const token = localStorage.getItem("token");

        if (token) {
          return {
            url: `/get-logged-user-info`,
            method: "GET",
          }
        }
      },
      providesTags: ["loggedInUserInfo"],
    }),
    login: builder.mutation({
      query: (data) => ({
        url: `/login`,
        method: "POST",
        body: data,
      }),
    }),
    // logout: builder.mutation({
    //     query: ()=>({
    //         url: `/signOut`,
    //         method: 'POST'
    //     }),
    //     invalidatesTags: ['userInfo']
    // }),
    register: builder.mutation({
      query: (data) => ({
        url: `/register`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["loggedInUserInfo"],
    }),
    verifyAccount: builder.query({
      query: (id) => ({
        url: `/verify/account/${id}`,
        method: "POST",
        body: { id: id },
      }),
      invalidatesTags: ["loggedInUserInfo"],
    }),
    updateUserInfo: builder.mutation({
      query: ({ userId, data }) => ({
        url: `/update-user-info/${userId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getProfileInfo", "loggedInUserInfo"],
    }),
  }),
});

export const {
  useGetLoggedInUserInfoQuery,
  useLoginMutation,
  // useLogoutMutation,
  useRegisterMutation,
  useVerifyAccountQuery,
  useUpdateUserInfoMutation,
} = authSlice;
