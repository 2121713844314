import React, { useEffect, useState } from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useForm } from 'react-hook-form';
import SubmitButton from '../../components/submitButton/SubmitButton';
import { useSearchParams } from 'react-router-dom';

const LocationFilter = ({ resetForm }) => {
    /**-React Router-**/
    const [searchParams, setSearchParams] = useSearchParams()
    /**-variables-**/
    const updatedSearchParams = new URLSearchParams(searchParams.toString())

    /**-React Hooks-**/
    const [country, setCountry] = useState()
    const [region, setRegion] = useState()

    /**-Hook Form-**/
    const { register, handleSubmit, setValue, reset, formState: { isDirty } } = useForm()
    register("country")
    register("region")
    register("city")

    /**-useEffects-**/
    useEffect(()=> {
        setCountry('')
        setRegion('')
        reset()
    }, [resetForm, reset])

    /**-Event Handlers**/
    const handleCountryChange = (value) => {
        setCountry(value)
        setValue('country', value, { shouldValidate: true, shouldDirty: true })
    }
    const handleRegionChange = (value) => {
        setRegion(value)
        setValue('region', value, { shouldValidate: true, shouldDirty: true })
    }
    const handleLocationSubmit = (data) => {
        data = Object.entries(data).filter(([key, value]) => !!value).map(([key, value]) => value).reverse().join(',')
        updatedSearchParams.set("lc", data)
        setSearchParams(updatedSearchParams)
    }

    return (
        <form
            onSubmit={handleSubmit(handleLocationSubmit)}
        >
            {/* <label className='fw-semibold text-secondary'>Location</label> */}
            <div className="d-grid gap-2">
                <div className=''>
                    <div className="d-grid gap-2">
                        <label className='small'>Country</label>
                        <CountryDropdown
                            className="form-control text-secondary shadow-sm border-1"
                            value={country}
                            onChange={handleCountryChange}
                        />
                    </div>
                </div>
                <div className=''>
                    <div className="d-grid gap-2">
                        <label className='small'>Region</label>
                        <RegionDropdown
                            className="form-select text-secondary shadow-sm border-1"
                            placeholder="Select region"
                            country={country}
                            value={region}
                            onChange={handleRegionChange}
                        />
                    </div>
                </div>
                <div className="">
                    <div className='d-grid gap-2'>
                        <label className='small'>City</label>
                        <input
                            type="text"
                            name="role"
                            {...register('city')}
                            className="border-1 rounded p-2 form-control"
                            placeholder='City?'
                        />
                    </div>
                </div>
                <div className="col-12 d-flex justify-content-end">
                    <SubmitButton text="Apply" disable={!isDirty} />
                </div>
            </div>
        </form>
    );
};

export default LocationFilter;