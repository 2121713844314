import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TargetLink from './targetLink/TargetLink';

const Footer = () => {

  const currentDate = new Date().getFullYear()
  return (
    <>
      <Container fluid className="bg-dark-blue">
        <div className="p-3 text-center">
          <p className="text-light mb-0">
            &#169; &nbsp; {currentDate} CFI Cast, &nbsp; All rights reserved.&nbsp;
            &nbsp;
            <a className='no-link' href='https://cficast.org/about/'>About us</a>
          </p>
        
        </div>
      </Container>

    </>
  );
};

export default Footer;