import { apiSlice } from "../api/apiSlice";

export const categorySlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({

        getCastOptions: builder.query({
            query: () => `/get-cast-options`,
            providesTags: ["getCastOptions"]
        }),
        getAllCategories: builder.query({
            query: () => `/get-all-categories`,
            providesTags: ["getAllCategories"]
        }),
        getSubcategories: builder.mutation({
            query: (categoryId) => ({
                url: `/get-subcategories/${categoryId}`,
                method: "GET"
            }),
        }),

    })
})

export const {
    useGetCastOptionsQuery,
    useGetAllCategoriesQuery,
    useGetSubcategoriesMutation
} = categorySlice