import { apiSlice } from "../api/apiSlice";
import { buildParam } from "../utils";

export const jobSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getJobs: builder.mutation({
            query: () => `/jobs`,
        }),
        searchJobs: builder.mutation({
            query: ({ type, gender, age, lc, date, pt, ps }) => ({
                url: `/search/jobs?type=${type}${buildParam("gender", gender)}${buildParam("age", age)}${buildParam("lc", lc)}${buildParam("date", date)}${buildParam("pt", pt)}${buildParam("ps", ps)}`
            })
        })
    })
})

export const {
    useGetJobsMutation,
    useSearchJobsMutation
} = jobSlice