import React, { useEffect, useMemo, useState } from "react";
import { Navbar, Offcanvas, Row } from "react-bootstrap";
import { Nav, CloseButton } from "react-bootstrap";
import { FiLogOut, FiLogIn, FiSettings, FiUser } from "react-icons/fi";
// import defaultProfileImage from '../../assets/images/placeholder-profile.jpg'
// import { BiChat, BiSearch } from 'react-icons/bi'
import { BiSearch } from "react-icons/bi";
import { Link } from "react-router-dom";
import defaultProfileImage from "../../assets/images/placeholder-profile.jpg";
import { useGetLoggedInUserInfoQuery } from "../../features/auth/authSlice";
import logo from "../../assets/images/cfi_cast_logo_new.png";
import logOut from "../../utils/logOut";
import HeaderSearch from "./HeaderSearch";
import { useKeycloak } from "@react-keycloak/web";
import { useGetProfileInfoQuery } from "../../features/profile/profileSlice";
import { GrGroup } from "react-icons/gr";
import { MdOutlineForum } from "react-icons/md";

const Header = () => {
  /**-React Hooks-**/
  //states
  const { keycloak } = useKeycloak();
  const [show, setShow] = useState(false);

  /**-RTK-**/
  const { data: loggedInUserData } = useGetLoggedInUserInfoQuery();
  const { data: profileData } = useGetProfileInfoQuery(
    loggedInUserData?.data?.user_id
  );

  /**-Variables- **/
  const userId = keycloak?.tokenParsed?.sub;
  const userData = loggedInUserData?.data;

  /**-Event Handlers-**/
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="bg-dark-blue">
      <div className="container">
        <Navbar lg={5} expand="lg" className="shadow-sm" varient="dark">
          <Navbar.Brand>
            <Link
              to="/"
              className="fw-bolder mb-1 fs-4 text-uppercase nav-link"
            >
              <img style={{ width: "200px" }} src={logo} alt="logo" />
            </Link>
          </Navbar.Brand>
          <div className="hstack">
            <Navbar.Toggle
              className="m-0 border-0"
              aria-controls="navbarScroll"
            >
              {userData ? (
                <BiSearch className="text-white border-0 fs-1" />
              ) : (
                <FiLogIn className="text-white border-0 fs-1" />
              )}
            </Navbar.Toggle>
            {userData && (
              <Nav.Link
                onClick={handleShow}
                className="d-xl-none  d-lg-none d-md-block border-0"
                aria-controls="navbarScroll"
              >
                <img
                  src={
                    loggedInUserData?.data?.profile_image ?? defaultProfileImage
                  }
                  style={{ height: "35px", width: "35px", objectFit: "cover" }}
                  className="rounded m-0 rounded-circle"
                  alt="profile-img"
                />
              </Nav.Link>
            )}
          </div>
          <Navbar.Collapse id="navbarScroll">
            {userData && <HeaderSearch />}

            <Nav className="ms-auto d-flex align-items-center">
              {/* <Nav.Link href="#" className="li-margin">
                <Link
                  to="/"
                  className="text-decoration-none text-light fw-bold"
                >
                  Movies
                </Link>
              </Nav.Link> */}
              {userData ? (
                <Nav.Link
                  href="#"
                  className="d-none d-xxl-block d-lg-block d-xl-block ms-2"
                  onClick={handleShow}
                >
                  <span className=" text-light fw-bold me-1">
                    {loggedInUserData?.data?.name ?? ""}{" "}
                    {loggedInUserData?.data?.last_name ?? ""}
                  </span>
                  <img
                    src={
                      loggedInUserData?.data?.profile_image ??
                      defaultProfileImage
                    }
                    style={{
                      height: "35px",
                      width: "35px",
                      objectFit: "cover",
                    }}
                    className={`rounded rounded-circle li-margin`}
                    alt="profile-img"
                  />
                </Nav.Link>
              ) : (
                <div className="d-flex gap-3">
                  <button
                    onClick={() => keycloak.login()}
                    className="bg-warning px-3 py-2 rounded"
                  >
                    Log in
                  </button>
                  <button
                    onClick={() => keycloak.register()}
                    className="bg-warning px-3 py-2 rounded"
                  >
                    Sign up
                  </button>
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="contianer">
          <Offcanvas
            className="offcanvas-width text-primary"
            style={{ color: "white" }}
            placement="end"
            show={show}
            onHide={handleClose}
          >
            <Offcanvas.Header className="bg-dark-blue">
              <Offcanvas.Title className="text-white fs-4">
                User Account
              </Offcanvas.Title>
              <CloseButton onClick={handleClose} className="bg-white" />
            </Offcanvas.Header>
            <Offcanvas.Body className="bg-white text-light">
              <Row>
                <div className="d-flex canvas-items justify-content-center">
                  <Link
                    to={`/profile/${userId}`}
                    className="text-secondary"
                    onClick={handleClose}
                  >
                    <div className="p-3 border-bottom d-flex align-items-center">
                      <FiUser className="me-2 fs-5 text-black" />
                      <span>Profile</span>
                    </div>
                  </Link>
                  {/* <a
                    href={`/chat`}
                    className="text-secondary"
                    onClick={handleClose}
                  >
                    <div className="p-3 border-bottom d-flex align-items-center">
                      <BiChat className="me-2 fs-5 text-black" />
                      <span>Chat</span>
                    </div>
                  </a> */}

                  {/* <div className="p-3 d-flex align-items-center border-bottom">
                        <BiTimeFive className="me-2 fs-5" />
                        <Link
                          to="profile"
                          className="text-secondary"
                          onClick={handleClose}
                        >
                          Watch List
                        </Link>
                      </div> */}
                  <a
                    onClick={handleClose}
                    href={`https://community.cficast.com/groups`}
                    className="text-secondary"
                  >
                    <div className="p-3 d-flex align-items-center border-bottom">
                      <GrGroup className="me-2 fs-5 text-black" />
                      <span>Groups</span>
                    </div>
                  </a>
                  <a
                    onClick={handleClose}
                    href={`https://community.cficast.com/`}
                    className="text-secondary"
                  >
                    <div className="p-3 d-flex align-items-center border-bottom">
                      <MdOutlineForum className="me-2 fs-5 text-black" />
                      <span>Forums</span>
                    </div>
                  </a>
                  <Link
                    onClick={handleClose}
                    to={`/settings/${userId}`}
                    className="text-secondary"
                  >
                    <div className="p-3 d-flex align-items-center border-bottom">
                      <FiSettings className="me-2 fs-5 text-black" />
                      <span>Account Settings</span>
                    </div>
                  </Link>
                  {/* <Link
                    onClick={handleClose}
                    to={`/event/${'64ac192a928e7287ebbf14ca'}`}
                    className="text-secondary"
                  >
                    <div className="p-3 d-flex align-items-center border-bottom">
                      <AiOutlineCalendar className="me-2 fs-5 text-white" />
                      <span>Events</span>
                    </div>
                  </Link> */}
                  {/* <div className="p-3 d-flex align-items-center border-bottom">
                        <BsStar className="me-2 fs-5" />
                        <Link to="profile" className="text-secondary">
                          Celebraties
                        </Link>
                      </div> */}
                  <div
                    onClick={() => {
                      localStorage.removeItem("token");
                      keycloak.logout();
                    }}
                    className="p-3 d-flex align-items-center border-bottom cursor-pointer"
                  >
                    <FiLogOut className="me-2 fs-5 text-black" />
                    <div className="text-secondary">Log Out</div>
                  </div>

                  {/* <div className="p-3 border-bottom d-flex align-items-center">
                        <Link
                          to="login"
                          className="text-secondary"
                          onClick={handleClose}
                        >
                          login-page-demo
                        </Link>
                      </div>
                      <div className="p-3 border-bottom d-flex align-items-center">
                        <Link
                          to="register"
                          className="text-secondary"
                          onClick={handleClose}
                        >
                          register-page-demo
                        </Link>
                      </div> */}
                </div>
              </Row>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
    </div>
  );
};

export default Header;
