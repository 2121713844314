import React, { useState } from 'react';
import LocationFilter from './LocationFilter';
import PayTypeFilter from './PayTypeFilter';
import DateFilter from './DateFilter';
import CastCrewFilters from './CastCrewFilters';
import { Accordion } from 'react-bootstrap';

const Sidebar = () => {
    /**-React Hooks-**/
    const [resetForms, setResetForms] = useState()

    return (
        <div className='d-grid gap-3'>
            <div className='d-grid gap-3'>
                <Accordion
                    defaultActiveKey="0"
                >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <label className='text-dark fs-18px text-capitalize'>Location</label>
                        </Accordion.Header>
                        <Accordion.Body>
                            <LocationFilter resetForm={resetForms} />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <label className='text-dark fs-18px text-capitalize'>Pay Type</label>
                        </Accordion.Header>
                        <Accordion.Body>
                            <PayTypeFilter resetForm={resetForms} />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <label className='text-dark fs-18px text-capitalize'>Date</label>
                        </Accordion.Header>
                        <Accordion.Body>
                            <DateFilter resetForm={resetForms} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <CastCrewFilters
                setResetForms={setResetForms}
            />
        </div>
    );
};

export default Sidebar;