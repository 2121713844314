import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useGetLoggedInUserInfoQuery } from "../../features/auth/authSlice";
import Skeleton from "../../components/skeleton/Skeleton";
import { useKeycloak } from "@react-keycloak/web";

const Authentication = () => {
  /**-React Router-**/
  const { pathname } = useLocation();
  /**-RTK-**/
  //queries
  const { isFetching } = useGetLoggedInUserInfoQuery();
  /**-Variables-**/
  const { keycloak, initialized } = useKeycloak();

  const hasAccessToken = keycloak.authenticated;

  return (isFetching || !initialized) ? (
    <div className="vh-100">
      <Skeleton />
    </div>
  ) : hasAccessToken ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: pathname }} replace />
  );
};

export default Authentication;
