import Keycloak from "keycloak-js";

let initOptions = {
  url: process.env.REACT_APP_KEYCLOAK_BASE_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  onLoad: "check-sso",
  silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
};

const kc = new Keycloak(initOptions);

export default kc;
