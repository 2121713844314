import React from 'react';

const TargetLink = ({ link, children, className="text-decoration-none", ...rest }) => {
    return (
        <a
            target='_blank'
            rel="noopener noreferrer"
            href={link}
            className={className}
            {...rest}
        >
            {children}
        </a>
    );
};

export default TargetLink;