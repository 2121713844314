import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../features/api/apiSlice";
import { chatapi } from "../features/Xchat/api";
import { setupListeners } from "@reduxjs/toolkit/query";

const store = configureStore({
    reducer:{
        [apiSlice.reducerPath]: apiSlice.reducer,
        [chatapi.reducerPath]: chatapi.reducer
    },
    
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiSlice.middleware, chatapi.middleware)
});
setupListeners(store.dispatch);

export default store