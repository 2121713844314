import React, { Suspense } from 'react';
import Skeleton from '../skeleton/Skeleton';
import { Outlet } from 'react-router-dom';

const ReactSuspense = () => {
    return (
        <Suspense fallback={<div className='vh-100'><Skeleton /></div>} >
            <Outlet />
        </Suspense>
    );
};

export default ReactSuspense;