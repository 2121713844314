const warningUtil = {
    title: 'Are you sure?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete'
}

const successToastUtil = {
    icon: 'success',
    title: 'Account Verified Successfully',
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true
}

const errorToastUtil = {
    icon: 'error',
    title: 'Error Occurred',
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    showCloseButton: true
}

const redErrorToastUtil = {
    icon: 'error',
    title: 'Error Occurred',
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    showCloseButton: true,
    background: "#dc3545",
    iconColor: "white",
    color: "white",
    customClass: {
        title: "fs-12px",
        closeButton: "text-white",
    },
}

export { warningUtil, successToastUtil, errorToastUtil, redErrorToastUtil }