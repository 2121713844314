import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import ErrorText from '../../components/errorText/ErrorText';
import SubmitButton from '../../components/submitButton/SubmitButton';
import { Form } from 'react-bootstrap';

const PayTypeFilter = ({ resetForm }) => {
    /**-React Router-**/
    const [searchParams, setSearchParams] = useSearchParams()
    /**-variables-**/
    const updatedSearchParams = new URLSearchParams(searchParams.toString())

    /**-Hook Form-**/
    const { register, handleSubmit, reset, formState: { isDirty, errors } } = useForm()

    /**-useEffects-**/
    useEffect(()=> {
        reset()
    }, [resetForm, reset])

    /**-Event Handlers-**/
    const handlePayTypeSubmit = (data) => {
        updatedSearchParams.set("pt", data.pay_type)
        setSearchParams(updatedSearchParams)
    }

    return (
        <form
            onSubmit={handleSubmit(handlePayTypeSubmit)}
        >
            {/* <label className='fw-semibold text-secondary mb-1'>Pay Type</label> */}
            <div className="d-grid gap-2">
                <Form.Check
                    type="radio"
                    name="option"
                    id="union"
                    value="union"
                    label="Union"
                    {...register("pay_type")}
                />
                <Form.Check
                    type="radio"
                    name="option"
                    id="non-union"
                    value="non-union"
                    label="Non-Union"
                    {...register("pay_type")}
                />
                <Form.Check
                    type="radio"
                    name="option"
                    id="volunteer"
                    value="volunteer"
                    label="Volunteer"
                    {...register("pay_type")}
                />
                {errors?.pay_type && <ErrorText text={errors?.pay_type?.message} />}
                <div className="col-12 d-flex justify-content-end">
                    <SubmitButton text="Apply" disable={!isDirty} />
                </div>
            </div>
        </form>
    );
};

export default PayTypeFilter;